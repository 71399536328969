import React, {useEffect, useState} from 'react';
import Legal from "../../components/legal/legal";
import PolicyItem from "../../components/policy-item/policy-item";
import Layout from "../../components/layout/layout";
import SwitchButton from "../../components/switch-button/switch-button";
import {connect} from "react-redux";
import "./cookie-notice.scss";
import {ToastContainer} from "react-toastify";

const LegalCookiesNotice = ({enableGoogleAnalytics, disableGoogleAnalytics}) => {
    const [googleAnalyticsEnabled, setGoogleAnalyticsEnabled] = useState(null);

    useEffect(() => {
        const gaProperty = 'G-6PD37M8584';
        const disableGaStr = 'ga-disable-' + gaProperty;
        if (document.cookie.indexOf(disableGaStr + '=true') > -1) {
            window[disableGaStr] = true;
            setGoogleAnalyticsEnabled(false);
        }
        else {
            setGoogleAnalyticsEnabled(true);
        }

    }, []);


    if (googleAnalyticsEnabled === null) {
        return null;
    }

    return (
        <Layout>
            <ToastContainer/>
            <Legal
                title="AfriMed Cookie Policy"
                subTitle="Please read this Cookie Policy alongside our <a href='/legal/privacy'>Privacy Policy</a>."
            >
                <PolicyItem
                    title="Use of Cookies on our website"
                    content="<p>We use cookies to collect information about your online preference. Cookies are small pieces of information sent by a web server to a web browser which allows the server to uniquely identify the browser on each page. You can learn more about cookies and how to disable/enable them here.</p>

<p>We use the following categories of cookies on our website:</p>
"/>
                <PolicyItem
                    title="Strictly Necessary Cookies"
                    content="These cookies are essential in order to enable you to move around the website and use its features. Without these cookies, services you have asked for such as remembering your login details or shopping basket items cannot be provided."
                    additionalContent={<div className={"AdditionalTag"}>Always active</div>}
                />

                <PolicyItem
                    title="Statistics Cookies"
                    additionalContent={<SwitchButton initialState={googleAnalyticsEnabled}
                                                     onHandler={enableGoogleAnalytics}
                                                     offHandler={disableGoogleAnalytics}
                                                     enabled={true}
                    />}
                    content="Cookies used for analytics help collect data that allows us to understand how visitors interact with the website. These insights allow us to improve the content and to build a better user’s experience."
                />
                <PolicyItem
                    title="Performance Cookies"
                    content="These cookies collect anonymous information on how people use our website. For example, we use Google Analytics cookies to help us understand how customers arrive at our site, browse or use our site and highlight areas where we can improve areas such as navigation, shopping experience and marketing campaigns. The data stored by these cookies never shows personal details from which your individual identity can be established."
                    additionalContent={<SwitchButton initialState={false}
                                                     onHandler={()=>{}}
                                                     offHandler={()=>{}}
                                                     enabled={false}
                    />}

                />
                <PolicyItem
                    title="Functionality Cookies"
                    content="These cookies remember choices you make such as the country you visit our website from, language and search parameters such as size, colour or product line. These can then be used to provide you with an experience more appropriate to your selections and to make the visits more tailored and pleasant. The information these cookies collect may be anonymised and they cannot track your browsing activity on other websites."
                    additionalContent={<SwitchButton initialState={false}
                                                     onHandler={()=>{}}
                                                     offHandler={()=>{}}
                                                     enabled={false}
                    />}

                />
                <PolicyItem
                    title="Targeting Cookies or Advertising Cookies"
                    content="These cookies collect information about your browsing habits in order to make advertising more relevant to you and your interests. They are also used to limit the number of times you see an advert as well as help measure the effectiveness of an advertising campaign. The cookies are usually placed by third party advertising networks. They remember the websites you visit and that information is shared with other parties such as advertisers."
                    additionalContent={<SwitchButton initialState={false}
                                                     onHandler={()=>{}}
                                                     offHandler={()=>{}}
                                                     enabled={false}
                    />}
                />

                <PolicyItem
                    title="Social Media Cookies"
                    content="<p<These cookies allow you to share what you’ve been doing on the AfriMed Platform, websites and on social media such as Facebook and Twitter. These cookies are not within our control. Please refer to the respective privacy policies for how their cookies work.</p>
                <p>If you want to delete any cookies that are already on your computer, please refer to the help and support area on your internet browser for instructions on how to locate the file or directory that stores cookies. More information about how to do this is given in the link above.</p>
                <p>If you want to learn more about what targeting and advertising cookies are on your device (whether or not related to us) and how to disable them you can do so <a href='https://afrimedtelehealth.com/legal/cookie-notice'>here</a>.</p>
                "
                    additionalContent={<SwitchButton initialState={false}
                                                     onHandler={()=>{}}
                                                     offHandler={()=>{}}
                                                     enabled={false}
                    />}
                />
                <PolicyItem
                    title="Use of Web Beacons"
                    content="<p>Some of our web pages may contain electronic images known as Web beacons (sometimes known as clear gifs) that allow us to count users who have visited these pages. Web beacons collect only limited information which including a cookie number, time and date of a page view, and a description of the page on which the web beacon resides. We may also carry web beacons placed by third party advertisers. These beacons do not carry any personally identifiable information and are only used to track the effectiveness of a particular campaign.</p>
<p>We may also use web beacons or similar technology to learn whether or not emails sent to you have been opened.</p>
<div style='font-size:14px;line-height:16px;'>
</div>
"
                />
            </Legal>
        </Layout>
    )
}

const mapStateToProps = state => {
    return {
        ...state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        enableGoogleAnalytics: () => dispatch({type: `ENABLE_GOOGLE_ANALYTICS`}),
        disableGoogleAnalytics: () => dispatch({type: `DISABLE_GOOGLE_ANALYTICS`}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LegalCookiesNotice)
