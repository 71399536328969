import "./switch-button.scss"
import React from "react"
import {useState} from "react";

const SwitchButton = ({initialState, onHandler, offHandler,enabled=true}) => {
    const [state, setState] = useState(initialState);

    const switchStateOn = () => {
        if (!state) {
            onHandler();
            setState(true);
        }
    }

    const switchStateOff = () => {
        if (state) {
            offHandler();
            setState(false);
        }
    }

    return (
        <>
            <div className={`SwitchContainer ${!enabled ? "Disabled":""} ${state ? "StateOn":"StateOff"}`}>
                <div className={`OffState ${!state ? "Active" : ""}`} onClick={switchStateOff}>
                </div>
                <div className={`OnState ${state ? "Active" : ""}`} onClick={switchStateOn}>
                </div>
            </div>
        </>
    )
}


export default SwitchButton;
